import React, { useRef } from 'react'

import t from 'prop-types'
import {
  Button,
  Divider,
  Header,
  Icon,
  Image,
  Input,
  ListHeader,
  ListItem,
  Popup,
} from 'semantic-ui-react'

import Loading from '~/components/Loading'

import { HeaderContainer, DeleteIcon, ImagesContainer } from './styles'

export function UnproductiveData({
  dataOfAnswers,
  enabledEdit,
  onHandleChangeAnswers,
  onOpenPhotos,
  onRemoveImage,
  isUploadingImage,
  missionName,
  canEdit,
  place,
  onClickEdit,
  onChangeImages,
}) {
  const myRef = useRef()

  async function handleUpload(event) {
    const file = event.target.files[0]

    if (file) {
      const imageUrl = URL.createObjectURL(file)
      onHandleChangeAnswers({
        id: 'observation_image_paths',
        value: imageUrl,
      })

      onChangeImages(file, 'observation_image_paths')
    }
  }

  if (isUploadingImage) {
    return <Loading customText="Enviando Imagem" />
  }

  return (
    <>
      <HeaderContainer>
        <Header as="h1">Item Não Coletado em {missionName}</Header>
        {canEdit && (
          <Popup
            content="Habilitar Edição de Respostas"
            trigger={
              <Icon
                size="large"
                link
                name="edit outline"
                color="blue"
                onClick={() => onClickEdit('unproductive')}
              />
            }
          />
        )}
      </HeaderContainer>
      <Header as="h3">Endereço: {place}</Header>
      <Divider />
      <Header as="h1">Opção: </Header>
      <Header as="h3">
        {enabledEdit ? (
          <Input
            value={dataOfAnswers?.observation}
            onChange={(_, { value }) =>
              onHandleChangeAnswers({
                id: 'observation',
                value,
              })
            }
          />
        ) : (
          dataOfAnswers?.observation
        )}
      </Header>

      <Header as="h1">Detalhes:</Header>
      <Header as="h3">
        {enabledEdit ? (
          <Input
            value={dataOfAnswers?.observation_description}
            onChange={(_, { value }) =>
              onHandleChangeAnswers({
                id: 'observation_description',
                value,
              })
            }
          />
        ) : (
          dataOfAnswers?.observation_description
        )}
      </Header>

      <ListItem style={{ marginBottom: 10 }}>
        <ListHeader as="h1">Imagens:</ListHeader>
        <p>
          {dataOfAnswers?.observation_image_paths.map(item => (
            <ImagesContainer key={item.path}>
              <Image
                src={item?.path}
                key={item?.path}
                onClick={() =>
                  onOpenPhotos(dataOfAnswers?.observation_image_paths)
                }
                size="medium"
                alt="del_img"
              />
              {enabledEdit && (
                <DeleteIcon onClick={() => onRemoveImage(item.path)} />
              )}
            </ImagesContainer>
          ))}
        </p>
        {enabledEdit && (
          <>
            <input
              ref={myRef}
              type="file"
              id="uploadImage"
              accept="image/*"
              onChange={handleUpload}
              style={{ display: 'none' }}
            />

            <Button primary onClick={() => myRef.current.click()}>
              Adicionar Imagem
            </Button>
          </>
        )}
      </ListItem>
    </>
  )
}

UnproductiveData.propTypes = {
  dataOfAnswers: t.shape({
    observation: t.string,
    observation_description: t.string,
    observation_image_paths: t.arrayOf({
      path: t.string,
    }),
  }),
  enabledEdit: t.bool,
  onHandleChangeAnswers: t.func,
  onOpenPhotos: t.func,
  onRemoveImage: t.func,
  isUploadingImage: t.bool,
  missionName: t.string,
  canEdit: t.bool,
  place: t.string,
  onClickEdit: t.func,
  onChangeImages: t.func,
}
