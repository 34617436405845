import React, { useEffect, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'
import { Button, Card, Grid, Header } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loading'
import { useDelivery } from '~/hooks/useDelivery'
import api from '~/services/api'

import { CollectAndDeliveryData } from './CollectAndDeliveryData'
import { Map } from './Map'
import { ActionsContainer } from './styles'
import { UnproductiveData } from './UnproductiveData'

export default function Delivery({ id, place, missionName, canEdit }) {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lightBox, setLightBox] = useState(false)
  const [photos, setPhotos] = useState([])
  const [missionsUsersDeliveryType, setMissionsUsersDeliveryType] = useState('')

  const [enabledCollectEdit, setEnabledCollectEdit] = useState(false)
  const [enabledDeliveryEdit, setEnabledDeliveryEdit] = useState(false)
  const [enabledUnproductiveEdit, setEnabledUnproductiveEdit] = useState(false)

  const [collectAnswers, setCollectAnswers] = useState([])
  const [deliveryAnswers, setDeliveryAnswers] = useState([])
  const [unproductiveAnswers, setUnproductiveAnswers] = useState({})

  const {
    updateCollectData,
    updateDeliveryData,
    updateUnproductiveData,
    uploadImage,
    isUploadingImage,
    imageUrl,
    generateUrlImage,
    isUpdating,
  } = useDelivery()

  async function getData() {
    setLoading(true)
    const res = await api.get(`delivery/registers/${id}`)
    setLoading(false)

    setResult(res?.data)

    if (res.data?.collectFeed) {
      setViewPortCollect({
        zoom: 16,
        transitionDuration: 5000,
        transitionEasing: d3.easeCubic,
        latitude: res.data?.collectFeed[0]?.lat,
        longitude: res.data?.collectFeed[0]?.lng,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }

    if (res?.data?.delivery_lat !== null) {
      setViewPortDelivery({
        zoom: 16,
        transitionDuration: 5000,
        transitionEasing: d3.easeCubic,
        latitude: Number(res.data?.result?.delivery_lat),
        longitude: Number(res.data?.result?.delivery_lng),
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
  }

  const viewportDefault = {
    zoom: 16,
    latitude: 0,
    longitude: 0,
    transitionDuration: 5000,
    transitionEasing: d3.easeCubic,
    transitionInterpolator: new FlyToInterpolator(),
  }

  const [viewportCollect, setViewPortCollect] = useState({
    ...viewportDefault,
  })

  const [viewportDelivery, setViewPortDelivery] = useState({
    ...viewportDefault,
  })

  const onClickCollect = viewportData =>
    setViewPortCollect(prev => ({ ...prev, ...viewportData }))

  const onClickDelivery = viewportData =>
    setViewPortDelivery(prev => ({ ...prev, ...viewportData }))

  function onOpenPhotos(items) {
    setPhotos(items)
    setLightBox(true)
  }

  function handleOnClickEdit({ deliveryType }) {
    const deliveryTypeSetters = {
      collect: setEnabledCollectEdit,
      delivery: setEnabledDeliveryEdit,
      unproductive: setEnabledUnproductiveEdit,
    }

    setMissionsUsersDeliveryType(deliveryType)

    if (deliveryTypeSetters[deliveryType]) {
      deliveryTypeSetters[deliveryType](true)
    }
  }

  const handleOnSubmitUpdate = () => {
    const updateFunctions = {
      collect: {
        update: updateCollectData,
        disable: setEnabledCollectEdit,
        data: collectAnswers,
      },

      delivery: {
        update: updateDeliveryData,
        disable: setEnabledDeliveryEdit,
        data: deliveryAnswers,
      },

      unproductive: {
        update: updateUnproductiveData,
        disable: setEnabledUnproductiveEdit,
        data: unproductiveAnswers,
      },
    }

    const updateAction = updateFunctions[missionsUsersDeliveryType]

    if (updateAction) {
      updateAction.update({ missionsUsersId: id, data: updateAction.data })
      updateAction.disable(false)
    }

    setMissionsUsersDeliveryType('')
  }

  const handleOnChangeAnswers = ({ id: answerId, value }) => {
    const isPhoto = value.includes('blob')
    const formattedValue = isPhoto ? { path: value } : value

    const answerSetters = {
      collect: setCollectAnswers,
      delivery: setDeliveryAnswers,
      unproductive: setUnproductiveAnswers,
    }

    const updateAnswers = prevState => {
      if (missionsUsersDeliveryType === 'unproductive') {
        return {
          ...prevState,
          [answerId]: isPhoto
            ? [...(prevState[answerId] || []), formattedValue]
            : value,
        }
      }

      return {
        ...prevState,
        fields: prevState?.fields?.map(item =>
          item.id === answerId
            ? {
                ...item,
                value: isPhoto ? [...item.value, formattedValue] : value,
              }
            : item
        ),
      }
    }

    if (answerSetters[missionsUsersDeliveryType]) {
      answerSetters[missionsUsersDeliveryType](updateAnswers)
    }
  }

  function handleRemoveImage(path, answerId) {
    const answerSetters = {
      collect: setCollectAnswers,
      delivery: setDeliveryAnswers,
      unproductive: setUnproductiveAnswers,
    }

    const updateAnswers = prevState => {
      if (missionsUsersDeliveryType === 'unproductive') {
        return {
          ...prevState,
          observation_image_paths: prevState.observation_image_paths.filter(
            item => item.path !== path
          ),
        }
      }

      return {
        ...prevState,
        fields: prevState?.fields?.map(item =>
          item.id === answerId
            ? { ...item, value: item.value.filter(i => i.path !== path) }
            : item
        ),
      }
    }

    if (answerSetters[missionsUsersDeliveryType]) {
      answerSetters[missionsUsersDeliveryType](updateAnswers)
    }
  }

  function handleUploadImage(file) {
    uploadImage(file)
  }

  const handleOnChangeImages = (file, answerId) => {
    generateUrlImage(file, answerId)
  }

  function handleSetStatesToDefaultValues() {
    setCollectAnswers(result?.result?.collect_data)
    setDeliveryAnswers(result?.result?.delivery_data)
    setUnproductiveAnswers(result?.result?.unproductive_data)
  }

  useEffect(() => {
    if (missionsUsersDeliveryType === 'unproductive') {
      setUnproductiveAnswers(prevState => ({
        ...prevState,
        observation_image_paths: [
          ...prevState.observation_image_paths,
          {
            path: imageUrl,
          },
        ],
      }))
    }
  }, [imageUrl]) // eslint-disable-line

  useEffect(() => {
    getData()
  }, []) // eslint-disable-line

  useEffect(() => {
    handleSetStatesToDefaultValues()
  }, [result?.result]) // eslint-disable-line

  if (result?.result === null || result?.length === 0) {
    return (
      <EmptyList
        icon="map marker alternate"
        text="Ainda Não Foram Realizados os Registros de Entrada e Saída"
      />
    )
  }

  const shouldRenderObservation = result?.result?.observation

  const shouldRenderUnproductive = result?.result?.unproductive_data

  const shouldRenderCollect =
    result?.result?.observation === null &&
    result?.result?.unproductive_data === null

  const shouldRenderDelivery = result?.result?.delivery_data !== null

  const isLoading = isUpdating || loading || isUploadingImage

  if (isLoading) {
    return <Loading customText="Carregando..." />
  }

  return !isLoading ? (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Map
            items={result?.collectFeed?.map((item, index) => ({
              ...item,
              index,
            }))}
            viewport={viewportCollect}
            onChange={onClickCollect}
          />
          <Card centered fluid>
            <Card.Content>
              {shouldRenderObservation ? (
                <>
                  <Header as="h1">Observação</Header>
                  <Header as="h3">{result?.result?.observation}</Header>
                </>
              ) : null}

              {shouldRenderUnproductive ? (
                <UnproductiveData
                  dataOfAnswers={unproductiveAnswers}
                  onOpenPhotos={values => onOpenPhotos(values)}
                  onHandleChangeAnswers={handleOnChangeAnswers}
                  enabledEdit={enabledUnproductiveEdit}
                  onRemoveImage={path => handleRemoveImage(path)}
                  onUploadImage={file => handleUploadImage(file)}
                  isUploadingImage={isUploadingImage}
                  canEdit={canEdit}
                  missionName={missionName}
                  place={place}
                  onClickEdit={deliveryType =>
                    handleOnClickEdit({
                      deliveryType,
                    })
                  }
                  onClickCancel={() => setEnabledUnproductiveEdit(false)}
                  onSubmitUpdate={handleOnSubmitUpdate}
                  onChangeImages={handleOnChangeImages}
                />
              ) : null}

              {shouldRenderCollect ? (
                <CollectAndDeliveryData
                  dataOfQuestions={result?.result?.collect_fields}
                  dataOfAnswers={collectAnswers}
                  enabledEdit={enabledCollectEdit}
                  onHandleChangeAnswers={handleOnChangeAnswers}
                  onOpenPhotos={value => onOpenPhotos(value)}
                  header={`Item Coletado em ${missionName}`}
                  place={place}
                  canEdit={canEdit}
                  onClickEdit={() =>
                    handleOnClickEdit({
                      deliveryType: 'collect',
                    })
                  }
                  onChangeImages={handleOnChangeImages}
                  onRemoveImage={(path, answerId) =>
                    handleRemoveImage(path, answerId)
                  }
                  disableEdit={
                    missionsUsersDeliveryType.length > 0 &&
                    missionsUsersDeliveryType !== 'collect'
                  }
                />
              ) : null}
            </Card.Content>
          </Card>
          {enabledUnproductiveEdit && (
            <ActionsContainer>
              <Button
                type="button"
                onClick={() => {
                  setEnabledUnproductiveEdit(false)
                  setMissionsUsersDeliveryType('')
                  handleSetStatesToDefaultValues()
                }}
              >
                Cancelar
              </Button>
              <Button primary type="button" onClick={handleOnSubmitUpdate}>
                Salvar
              </Button>
            </ActionsContainer>
          )}

          {enabledCollectEdit && (
            <ActionsContainer>
              <Button
                type="button"
                onClick={() => {
                  setEnabledCollectEdit(false)
                  setMissionsUsersDeliveryType('')
                  handleSetStatesToDefaultValues()
                }}
              >
                Cancelar
              </Button>
              <Button primary type="button" onClick={handleOnSubmitUpdate}>
                Salvar
              </Button>
            </ActionsContainer>
          )}
        </Grid.Column>

        {result?.result?.delivery_lat !== null ? (
          <Grid.Column>
            <Map
              items={[
                {
                  index: 1,
                  lat: Number(result?.result?.delivery_lat),
                  lng: Number(result?.result?.delivery_lng),
                  action: 'delivered',
                  created_at: new Date(),
                },
              ]}
              viewport={viewportDelivery}
              onChange={onClickDelivery}
            />
            <Card centered fluid>
              <Card.Content>
                {shouldRenderDelivery ? (
                  <CollectAndDeliveryData
                    header={`Entrega em ${result?.result?.delivery_name}`}
                    canEdit={canEdit}
                    onClickEdit={() =>
                      handleOnClickEdit({
                        deliveryType: 'delivery',
                      })
                    }
                    place={result?.result?.delivery_address}
                    dataOfQuestions={result?.result?.delivery_places_data}
                    dataOfAnswers={deliveryAnswers}
                    enabledEdit={enabledDeliveryEdit}
                    onHandleChangeAnswers={handleOnChangeAnswers}
                    onOpenPhotos={value => onOpenPhotos(value)}
                    onChangeImages={handleOnChangeImages}
                    onRemoveImage={(path, answerId) =>
                      handleRemoveImage(path, answerId)
                    }
                    disableEdit={
                      missionsUsersDeliveryType.length > 0 &&
                      missionsUsersDeliveryType !== 'delivery'
                    }
                  />
                ) : null}
              </Card.Content>
            </Card>
            {enabledDeliveryEdit && (
              <ActionsContainer>
                <Button
                  type="button"
                  onClick={() => {
                    setEnabledDeliveryEdit(false)
                    setMissionsUsersDeliveryType('')
                    handleSetStatesToDefaultValues()
                  }}
                >
                  Cancelar
                </Button>
                <Button primary type="button" onClick={handleOnSubmitUpdate}>
                  Salvar
                </Button>
              </ActionsContainer>
            )}
          </Grid.Column>
        ) : null}
      </Grid.Row>
      <ModalGateway>
        {lightBox ? (
          <Modal onClose={() => setLightBox(false)}>
            <Carousel
              showThumbnails
              views={photos.map(i => ({ ...i, source: i.path }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Grid>
  ) : null
}

Delivery.propTypes = {
  id: t.string,
  place: t.string,
  missionName: t.string,
  canEdit: t.bool,
}
