// /* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react'

import t from 'prop-types'
import {
  List,
  Image,
  ListHeader,
  ListItem,
  Input,
  Header,
  Popup,
  Icon,
  Divider,
  Button,
} from 'semantic-ui-react'

import Loading from '~/components/Loading'

import { HeaderContainer, DeleteIcon, ImagesContainer } from './styles'

export function CollectAndDeliveryData({
  dataOfQuestions,
  dataOfAnswers,
  enabledEdit,
  onHandleChangeAnswers,
  onOpenPhotos,
  canEdit,
  onClickEdit,
  place,
  header,
  onChangeImages,
  isUploadingImage,
  onRemoveImage,
  disableEdit,
}) {
  const inputRefs = useRef({})

  const getInputRef = id => {
    if (!inputRefs.current[id]) {
      inputRefs.current[id] = React.createRef()
    }
    return inputRefs.current[id]
  }

  async function handleUpload(event, answerId) {
    const file = event.target.files[0]

    if (file) {
      const imageUrl = URL.createObjectURL(file)
      onHandleChangeAnswers({
        id: answerId,
        value: imageUrl,
      })

      onChangeImages(file, answerId)
    }
  }

  if (isUploadingImage) {
    return <Loading customText="Enviando Imagem" />
  }

  return (
    <>
      <HeaderContainer>
        <Header as="h1">{header}</Header>
        {canEdit && (
          <Popup
            content={
              disableEdit
                ? 'Conclua ou Cancele a Outra Edição'
                : 'Habilitar Edição de Respostas'
            }
            trigger={
              <Icon
                size="large"
                link={!disableEdit}
                name="edit outline"
                color="blue"
                onClick={deliveryType => onClickEdit(deliveryType)}
                disabled={disableEdit}
              />
            }
          />
        )}
      </HeaderContainer>
      <Header as="h3">Endereço: {place}</Header>
      <Divider />
      <List>
        {dataOfQuestions?.fields.map(question => {
          const answer = dataOfAnswers?.fields.find(a => a.id === question.id)
          const isImage =
            Array.isArray(answer?.value) &&
            // eslint-disable-next-line no-prototype-builtins
            answer?.value.every(item => item?.hasOwnProperty('path'))
          const isArray = Array.isArray(answer?.value) && !isImage
          const isString = typeof answer?.value === 'string'
          return (
            <ListItem style={{ marginBottom: 10 }}>
              <ListHeader as="h3">{question?.name}</ListHeader>
              <p>
                <p>
                  {isImage
                    ? answer?.value?.map(item => (
                        <>
                          <ImagesContainer key={item.path}>
                            <Image
                              size="medium"
                              alt="del_img"
                              src={item?.path}
                              key={item?.path}
                              onClick={() => onOpenPhotos(answer.value)}
                            />
                            {enabledEdit && (
                              <DeleteIcon
                                onClick={() =>
                                  onRemoveImage(item.path, answer.id)
                                }
                              />
                            )}
                          </ImagesContainer>
                        </>
                      ))
                    : null}
                </p>
                {isImage && enabledEdit && (
                  <>
                    <input
                      ref={getInputRef(answer.id)}
                      type="file"
                      id={`uploadImage-${answer?.id}`}
                      accept="image/*"
                      onChange={event => handleUpload(event, answer.id)}
                      style={{ display: 'none' }}
                    />

                    <Button
                      primary
                      onClick={() => getInputRef(answer.id).current.click()}
                    >
                      Adicionar Imagem
                    </Button>
                  </>
                )}
                {isArray ? (
                  <span style={{ fontSize: 14 }}>
                    {answer?.value.join(',')}
                  </span>
                ) : null}
                {isString ? (
                  <>
                    {enabledEdit ? (
                      <Input
                        value={answer?.value}
                        onChange={(_, { value }) =>
                          onHandleChangeAnswers({ id: answer.id, value })
                        }
                      />
                    ) : (
                      answer?.value
                    )}
                  </>
                ) : null}
              </p>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

CollectAndDeliveryData.propTypes = {
  dataOfQuestions: t.shape({
    fields: t.arrayOf({
      id: t.string,
      name: t.string,
      type: t.string,
      field: t.string,
      required: t.bool,
    }),
  }),
  dataOfAnswers: t.shape({
    fields: t.arrayOf({
      id: t.string,
      value: t.string,
    }),
  }),
  enabledEdit: t.bool,
  onHandleChangeAnswers: t.func,
  onOpenPhotos: t.func,
  canEdit: t.bool,
  onClickEdit: t.func,
  place: t.string,
  header: t.string,
  onChangeImages: t.func,
  isUploadingImage: t.bool,
  onRemoveImage: t.func,
  disableEdit: t.bool,
}
