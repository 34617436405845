import { useCallback, useState } from 'react'

import FileSaver from 'file-saver'
import { isNil } from 'ramda'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useBadges() {
  const [badges, setBadges] = useState([])
  const [loadingBadges, setLoadingBadges] = useState(false)
  const [badgesCount, setBadgesCount] = useState(0)
  const [badge, setBadge] = useState({})
  const [exclusiveBadges, setExclusiveBadges] = useState([])
  const [countExclusiveBadges, setCountExclusiveBadges] = useState([])
  const [badgesOptions, setBadgeOptions] = useState([])

  const getAllBadges = useCallback(async () => {
    try {
      setLoadingBadges(true)
      const res = await api.get('/badges/admin', {
        validateStatus() {
          return true
        },
      })
      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingBadges(false)
      const options = res.data.map(item => ({
        key: item.uid,
        value: item.uid,
        text: item.title,
      }))
      return setBadgeOptions(options)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }, [])

  const getBadges = useCallback(async params => {
    try {
      setLoadingBadges(true)
      const res = await api.get('/badges', {
        params,
        validateStatus() {
          return true
        },
      })
      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setBadgesCount(res.data.count)
      setLoadingBadges(false)
      return setBadges(res.data.badges)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }, [])

  const onChangeStatus = async params => {
    try {
      setLoadingBadges(true)

      const data = {
        ...params,
        active: !params.active,
      }

      const { uid, active } = data

      const res = await api.put(
        `/badges/${uid}`,
        { active },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const changedActive = {
        ...params,
        active: JSON.parse(res.data.active),
      }

      const index = badges.findIndex(i => i.uid === params.uid)
      badges[index] = changedActive
      setBadges(badges)

      return setLoadingBadges(false)
    } catch (error) {
      setLoadingBadges(false)
      throw new Error(error.message)
    }
  }

  const onSubmitCreate = async createData => {
    try {
      setLoadingBadges(true)

      const schema = Yup.object().shape({
        avatar: Yup.object().nullable().required('Imagem é obrigatória.'),
        title: Yup.string().required('Título obrigatório'),
        urlVideo: Yup.string().required('URL obrigatória'),
        description: Yup.string().required('Descrição obrigatória'),
      })

      const isValid = schema.isValidSync(createData)

      if (!isValid) {
        const validate = schema.validateSync(createData)
        return Message().error(validate)
      }

      const data = new FormData()

      data.set('title', createData.title)
      data.set('url_video', createData.urlVideo)
      data.set('description', createData.description)
      data.set('active', createData.active)
      data.set('exclusive', createData.isExclusive)
      data.set('is_consultant', createData.isConsultant)
      data.set('require_approval', createData.requireApproval)
      data.set('avg_time', createData.avgTime)
      data.set('avg_value', createData.avgValue)
      data.set('min_score', createData.minScore)

      if (!isNil(createData.customerId)) {
        data.set('customer_id', createData.customerId)
      }

      data.append('avatar', createData.avatar.data)

      const res = await api.post('badges', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingBadges(false)
      getBadges()
      return Message().success('Badge criado com sucesso!')
    } catch (error) {
      setLoadingBadges(false)
      return Message().error(error.message)
    }
  }

  const onSubmitUpdate = async updateData => {
    try {
      setLoadingBadges(true)

      const newData = new FormData()
      newData.set('title', updateData.title)
      newData.set('exclusive', updateData.isExclusive)
      newData.set('is_consultant', updateData.isConsultant)
      newData.set('require_approval', updateData.requireApproval)
      newData.set('url_video', updateData.urlVideo)
      newData.set('description', updateData.description)
      newData.set('avg_time', updateData.avgTime)
      newData.set('avg_value', updateData.avgValue)
      newData.set('min_score', updateData.minScore)

      if (!isNil(updateData.customerId)) {
        newData.set('customer_id', updateData.customerId)
      }

      if (!isNil(updateData.avatar.data)) {
        newData.append('avatar', updateData.avatar.data)
      }
      const res = await api.put(`badges/${updateData.badgeId}`, newData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getBadgeById(updateData.badgeId)
      setLoadingBadges(false)

      return Message().success('Badge alterado com sucesso!')
    } catch (error) {
      setLoadingBadges(false)
      return Message().error(error.message)
    }
  }

  async function getBadgeById(badgeId) {
    try {
      setLoadingBadges(true)
      const res = await api.get(`badges/${badgeId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setBadge(res.data.badge)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }

  async function getExclusives(params) {
    try {
      setLoadingBadges(true)

      const res = await api.get('badges-users-requests/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountExclusiveBadges(res.data.total)
      return setExclusiveBadges(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }

  async function changeStatus(userId, status) {
    try {
      setLoadingBadges(true)

      const res = await api.put(
        `badges-users-requests/${userId}`,
        {
          status,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTimeout(() => {
        setExclusiveBadges(prevState =>
          prevState.filter(prev => prev.id !== res.data.id)
        )
        setCountExclusiveBadges(prevState => prevState - 1)
      }, 200)

      return Message().success('Status Alterado com Sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }

  async function updateRequestsInBatch(params) {
    try {
      setLoadingBadges(true)

      const res = await api.put('badges-users-requests/in-batch', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Items atualizados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }

  async function exportReport(params) {
    try {
      setLoadingBadges(true)

      const res = await api.post('badges-users-requests/report', params, {
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      FileSaver.saveAs(blob, `badges_users_requests_${Date.now()}`)

      return Message().success('Dados exportados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingBadges(false)
    }
  }

  return {
    badges,
    getBadges,
    loadingBadges,
    badgesCount,
    onChangeStatus,
    onSubmitCreate,
    onSubmitUpdate,
    getBadgeById,
    badge,
    getExclusives,
    exclusiveBadges,
    countExclusiveBadges,
    changeStatus,
    getAllBadges,
    badgesOptions,
    updateRequestsInBatch,
    exportReport,
  }
}
