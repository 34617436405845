import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

function useDelivery() {
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [imagesPathsToUpload, setImagesPathsToUpload] = useState([])
  const [images, setImages] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  function resetImageStates() {
    setImagesPathsToUpload([])
    setImages([])
    setIsUpdating(false)
  }

  async function mappedFields(data) {
    const imagesUrl = await uploadImage()
    const { fields } = data

    const newFields = fields.map(field => {
      if (Array.isArray(field.value)) {
        const filteredValues = field.value.filter(
          item => !item.path.includes('blob')
        )

        const newImages = imagesUrl
          .filter(url => !url.path.includes('blob'))
          .filter(url => url.path.includes(field.id))
          .map(url => ({ path: url.path }))

        return {
          ...field,
          value: [...filteredValues, ...newImages],
        }
      }

      return field
    })

    return newFields
  }

  async function mappedUnproductive(data) {
    const imagesUrl = await uploadImage()
    const { observation_image_paths } = data

    const newUnproductiveImages = [...observation_image_paths, ...imagesUrl]
      .filter(initialImage => !initialImage.path.includes('blob'))
      .map(filteredImage => ({ path: filteredImage.path }))

    return newUnproductiveImages
  }

  async function uploadImage() {
    try {
      const result = []

      for (let index = 0; index < imagesPathsToUpload.length; index += 1) {
        const path = imagesPathsToUpload[index]
          .split('delivery')[1]
          .split('answerId')[0]

        const formData = new FormData()

        const info = {
          paths: [path],
          isPublic: true,
        }

        formData.append('file', images[index])
        formData.append('info', JSON.stringify(info))

        // eslint-disable-next-line no-await-in-loop
        const urlImage = await api.post('upload/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        result.push({ path: urlImage.data.paths.url })
      }

      return result
    } catch (error) {
      return Message().error('Erro no upload da imagem', error)
    }
  }

  async function updateCollectData(params) {
    try {
      setIsUpdating(true)

      imagesPathsToUpload.forEach(path =>
        console.log(path.split('delivery')[1].split('answerId')[0])
      )

      if (imagesPathsToUpload.length > 0) {
        params.data.fields = await mappedFields(params.data)
      }

      const res = await api.put('delivery/admin', {
        missions_users_id: params.missionsUsersId,
        collect_data: params.data,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Item atualizado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      resetImageStates()
    }
  }

  async function updateDeliveryData(params) {
    try {
      setIsUpdating(true)

      imagesPathsToUpload.forEach(path =>
        console.log(path.split('delivery')[1].split('answerId')[0])
      )

      if (imagesPathsToUpload.length > 0) {
        params.data.fields = await mappedFields(params.data)
      }

      const res = await api.put('delivery/admin/finished', {
        missions_users_id: params.missionsUsersId,
        delivery_data: params.data,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Item atualizado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      resetImageStates()
    }
  }

  async function updateUnproductiveData(params) {
    try {
      setIsUpdating(true)

      if (imagesPathsToUpload.length > 0) {
        params.data.observation_image_paths = await mappedUnproductive(
          params.data
        )
      }

      const res = await api.put('delivery/admin/no-collect', {
        missions_users_id: params.missionsUsersId,
        unproductive_data: params.data,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Item atualizado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      resetImageStates()
    }
  }

  async function generateUrlImage(file, answerId = '') {
    try {
      setIsUploadingImage(true)

      const res = await api.post('/upload/generate-signed-urls-to-upload', {
        isPublic: true,
        paths: [`delivery${answerId}answerId-${Date.now()}-${file.name}`],
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setImagesPathsToUpload(prevState => [...prevState, res.data.paths[0]])
      setImages(prevState => [...prevState, file])

      return Message().info(
        'Imagem adicionada com sucesso! Não esqueça de salvar para atualizar os dados'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setIsUploadingImage(false)
    }
  }

  return {
    updateCollectData,
    updateDeliveryData,
    updateUnproductiveData,
    uploadImage,
    generateUrlImage,
    isUploadingImage,
    isUpdating,
  }
}

export { useDelivery }
