import React, { useState } from 'react'

import t from 'prop-types'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Select,
} from 'semantic-ui-react'

import { missionsMainProps } from '../missionsMainProps'
import { SharedLinkModal } from './SharedLinkModal'

const approvationStatusOptions = [
  { key: 3, value: 3, text: 'Validando dados' },
  { key: 0, value: 0, text: 'Aguardando' },
]
export function Activities({
  getValues,
  openField,
  onChange,
  onChangeCheckbox,
}) {
  const [open, setOpen] = useState(false)

  return (
    <Grid.Column width={5}>
      <Form.Field
        toggle
        name="has_commission"
        checked={getValues('has_commission')}
        label="Comissão"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_bonus"
        checked={getValues('has_bonus')}
        label="Valor Bônus"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_value_variable"
        checked={getValues('has_value_variable')}
        label="Valor Variável"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_value_percentage"
        checked={getValues('has_value_percentage')}
        label="Valor Percentual"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_value_prime"
        checked={getValues('has_value_prime')}
        label="Valor Prime"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_value_black"
        checked={getValues('has_value_black')}
        label="Valor Black"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_points"
        checked={getValues('has_points')}
        control={Checkbox}
        onChange={onChangeCheckbox}
        label="Valor Pontuação (Pagamento em Pontos)"
      />

      <Form.Field
        toggle
        control={Checkbox}
        name="has_points_value"
        checked={getValues('has_points_value')}
        onChange={onChangeCheckbox}
        label="Valor Pontuação + Dinheiro (Pagamento em Pontos e Dinheiro)"
      />

      <Form.Field
        toggle
        control={Checkbox}
        name="has_recurring_payment"
        checked={getValues('has_recurring_payment')}
        onChange={onChangeCheckbox}
        label="Pagamento Recorrente"
      />

      <Divider />

      <Form.Field
        toggle
        name="has_photo"
        checked={getValues('has_photo')}
        label="Camera"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_products"
        checked={getValues('has_products')}
        label="Lista de Produtos"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      {openField?.has_products && (
        <>
          <Form.Field
            toggle
            id="has_invoice"
            label="Tem Orçamento"
            control={Checkbox}
            onChange={onChangeCheckbox}
          />
          {/* {openField?.has_invoice && (
            <>
              <Checkbox
                checked={openField?.invoice_items?.items.includes('PF') || false}
                onClick={(e, { checked }) =>
                  handleChangeCheckbox('PF', checked)
                }
                label="PF"
              />
              <Checkbox
                checked={invoice_items?.items.includes('PJ') || false}
                onClick={(e, { checked }) =>
                  handleChangeCheckbox('PJ', checked)
                }
                label="PJ"
              />
            </>
          )} */}
        </>
      )}

      <Form.Field
        toggle
        name="has_pos"
        checked={getValues('has_pos')}
        label="Ponto de Vendas"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="has_webview"
        checked={getValues('has_webview')}
        label="Ativar Webview"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      {openField?.has_webview ? (
        <Form.Input
          type="text"
          name="webview_link"
          checked={getValues('webview_link')}
          onChange={onChange}
          label="Adicione o link desejado"
          placeholder="Ex: https://www.missionbrasil.com.br"
        />
      ) : null}
      <Form.Field
        toggle
        name="has_import_image"
        checked={getValues('has_import_image')}
        label="Importar Fotos"
        control={Checkbox}
        onChange={onChangeCheckbox}
      />

      <Form.Field
        name="has_import_document"
        checked={getValues('has_import_document')}
        toggle
        onChange={onChangeCheckbox}
        label="Importar PDF"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_scanner"
        checked={getValues('has_scanner')}
        onChange={onChangeCheckbox}
        label="Scanner"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_forms"
        checked={getValues('has_forms')}
        onChange={onChangeCheckbox}
        label="Formulário"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_signature"
        checked={getValues('has_signature')}
        onChange={onChangeCheckbox}
        label="Assinatura Digital"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_checkin_checkout_night_time"
        checked={getValues('has_checkin_checkout_night_time')}
        onChange={onChangeCheckbox}
        label="Check-In / Check-Out no turno noturno"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="check_in_check_out"
        checked={getValues('check_in_check_out')}
        onChange={onChangeCheckbox}
        label="Check-In / Check-Out"
        control={Checkbox}
      />

      {openField?.check_in_check_out ? (
        <div style={styles.checkin}>
          <Form.Field
            toggle
            name="checkin_has_sms_confirm"
            checked={getValues('checkin_has_sms_confirm')}
            onChange={onChangeCheckbox}
            label="Deseja confirmar Check-In por SMS?"
            control={Checkbox}
          />

          <Form.Field
            toggle
            control={Checkbox}
            onChange={onChangeCheckbox}
            name="checkin_has_code_confirm"
            checked={getValues('checkin_has_code_confirm')}
            label="Deseja confirmar Check-In por Código Gerado pelo Gerente?"
          />

          <Form.Input
            name="check_distance"
            defaultValue={getValues('check_distance')}
            type="number"
            onChange={onChange}
            label="Distância para Check-In e Check-Out (Km)"
            placeholder="Ex: 10"
          />
        </div>
      ) : null}
      <Form.Field
        toggle
        name="has_cover_letter"
        checked={getValues('has_cover_letter')}
        onChange={onChangeCheckbox}
        label="Carta de Apresentação"
        control={Checkbox}
      />

      {openField?.has_cover_letter ? (
        <div style={styles.checkin}>
          <Form.Field
            toggle
            name="has_receipt"
            checked={getValues('has_receipt')}
            onChange={onChangeCheckbox}
            label="Deseja gerar Recibo?"
            control={Checkbox}
          />
          <Form.Input
            type="number"
            onChange={onChange}
            placeholder="Ex: 10"
            name="cover_letter_distance"
            label="Distância para Abrir Carta (Km)"
            defaultValue={getValues('cover_letter_distance')}
          />
        </div>
      ) : null}

      <Form.Field
        toggle
        name="has_collect"
        checked={getValues('has_collect')}
        onChange={onChangeCheckbox}
        label="Ponto de Coleta"
        control={Checkbox}
      />

      {openField?.has_collect && (
        <div style={styles.checkin}>
          <Form.Input
            name="collect_distance"
            defaultValue={getValues('collect_distance')}
            type="number"
            onChange={onChange}
            label="Distância para Confirmar coleta(Km)"
            placeholder="Ex: 10"
          />
        </div>
      )}

      <Form.Field
        toggle
        name="has_delivery_places"
        checked={getValues('has_delivery_places')}
        onChange={onChangeCheckbox}
        label="Ponto de entrega"
        control={Checkbox}
      />

      {openField?.has_delivery_places && (
        <div style={styles.checkin}>
          <Form.Input
            name="delivery_distance"
            defaultValue={getValues('delivery_distance')}
            type="number"
            onChange={onChange}
            label="Distância para Confirmar entrega(Km)"
            placeholder="Ex: 10"
          />
        </div>
      )}

      <Form.Field
        toggle
        name="has_rating"
        checked={getValues('has_rating')}
        onChange={onChangeCheckbox}
        label="Avaliações"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_attachment"
        checked={getValues('has_attachment')}
        onChange={onChangeCheckbox}
        label="Anexo em Missão"
        control={Checkbox}
      />

      <div style={styles.shared}>
        <Form.Field
          toggle
          name="has_shared_link"
          checked={getValues('has_shared_link')}
          onChange={onChangeCheckbox}
          label="Compartilhar Links"
          control={Checkbox}
        />

        {openField?.has_shared_link ? (
          <Button
            onClick={e => {
              e.preventDefault()
              setOpen(prev => !prev)
            }}
          >
            Configurar Integração
          </Button>
        ) : null}
      </div>
      <Divider />

      <Form.Field
        toggle
        control={Checkbox}
        onChange={onChangeCheckbox}
        name="should_inactivate_when_reproved"
        checked={getValues('should_inactivate_when_reproved')}
        label="Inativar missão após reprovação"
      />

      <Form.Field
        toggle
        name="can_multiple"
        checked={getValues('can_multiple')}
        control={Checkbox}
        onChange={onChangeCheckbox}
        label="Standy By (Fazer diversas vezes a mesma missão sem finalizar)"
      />

      <Form.Field
        toggle
        name="can_restart"
        checked={getValues('can_restart')}
        control={Checkbox}
        label="Refazer Missão"
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        control={Checkbox}
        name="has_auto_approval"
        checked={getValues('has_auto_approval')}
        onChange={onChangeCheckbox}
        label="Aprovação Automática"
      />

      {openField.has_auto_approval && (
        <div style={styles.checkin}>
          <label>
            Ativar aprovação automática para missões com o seguinte status:
          </label>
          <Select
            name="auto_approval_status"
            defaultValue={getValues('auto_approval_status')}
            onChange={onChange}
            options={approvationStatusOptions}
          />
        </div>
      )}

      <Form.Field
        toggle
        name="no_location"
        checked={getValues('no_location')}
        control={Checkbox}
        label="Não Localizado"
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        control={Checkbox}
        name="is_expiring_weekend"
        checked={getValues('is_expiring_weekend')}
        onChange={onChangeCheckbox}
        label="Expira Final de Semana"
      />

      <Form.Field
        toggle
        control={Checkbox}
        name="has_custom_status"
        checked={getValues('has_custom_status')}
        label="Status Customizado"
        onChange={onChangeCheckbox}
      />

      <Form.Field
        toggle
        name="show_only_if_badge"
        checked={getValues('show_only_if_badge')}
        onChange={onChangeCheckbox}
        label="Esconder Missões (Usuário sem badge)"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_check_availability"
        checked={getValues('has_check_availability')}
        onChange={onChangeCheckbox}
        label="Verificar Cobertura de Instalação"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_enable_scheduling"
        checked={getValues('has_enable_scheduling')}
        onChange={onChangeCheckbox}
        label="Agendamento"
        control={Checkbox}
      />

      <Form.Field
        toggle
        name="has_forms_items_overall"
        checked={getValues('has_forms_items_overall')}
        onChange={onChangeCheckbox}
        label="Pontuação Média de Seções"
        control={Checkbox}
      />

      <Divider />
      <SharedLinkModal
        open={open}
        setOpen={setOpen}
        onChange={onChange}
        settings={getValues('shared_links_settings')}
        integration={getValues('shared_links_api_integration')}
      />
    </Grid.Column>
  )
}

Activities.propTypes = {
  onChange: t.func,
  onChangeCheckbox: t.func,
  getValues: missionsMainProps.values,
  openField: missionsMainProps.values,
}

const styles = {
  checkin: {
    padding: 10,
    borderRadius: 10,
    border: '1px solid #bababa',
    marginBlock: 10,
  },
  shared: {},
}
