import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

export const ImagesContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 8px;
  }

  img {
    border-radius: 4px;
  }
`

export const DeleteIcon = styled(Icon).attrs({
  size: 'large',
  name: 'delete',
  color: 'red',
  link: true,
})`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 50%;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
