import React, { useState } from 'react'

import t from 'prop-types'
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Form,
  FormInput,
  Grid,
  Header,
  FormSelect,
  FormTextArea,
} from 'semantic-ui-react'

const customerToIntegrate = [
  { key: 'roxos', value: 'roxos', text: 'Roxos' },
  { key: 'igoal_beeleads', value: 'igoal_beeleads', text: 'Igoal/Beeleads' },
]

export function SharedLinkModal({
  integration,
  settings,
  open,
  onChange,
  setOpen,
}) {
  const options = [
    { key: 'GET', text: 'GET', value: 'GET' },
    { key: 'POST', text: 'POST', value: 'POST' },
    { key: 'PATCH', text: 'PATCH', value: 'POST' },
    { key: 'PUT', text: 'PUT', value: 'POST' },
  ]

  const [sharedLink, setSharedLink] = useState({})

  function onChangeValues(key, value) {
    setSharedLink(prev => ({ ...prev, [key]: { ...prev[key], ...value } }))

    return setTimeout(() => {
      onChange(null, {
        name: key,
        value: { ...sharedLink[key], ...value },
      })
    }, 1000)
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <ModalHeader>Integração Compartilhar Link</ModalHeader>
      <ModalContent>
        <Grid columns={2}>
          <Grid.Column>
            <Header>Configuração Compartilhamento</Header>
            <Form>
              <FormInput
                fluid
                label="Título"
                placeholder="Título..."
                onChange={({ target }) =>
                  onChangeValues('shared_links_settings', {
                    ...settings,
                    title: target.value,
                  })
                }
                defaultValue={settings?.title}
              />
              <FormInput
                fluid
                label="Assunto"
                placeholder="Assunto..."
                onChange={({ target }) =>
                  onChangeValues('shared_links_settings', {
                    ...settings,
                    subject: target.value,
                  })
                }
                defaultValue={settings?.subject || ''}
              />
              <FormInput
                fluid
                label="URL Video"
                onChange={({ target }) =>
                  onChangeValues('shared_links_settings', {
                    ...settings,
                    url_video: target.value,
                  })
                }
                defaultValue={settings?.url_video || ''}
                placeholder="URL Video Youtube"
              />
              <FormTextArea
                fluid
                label="Descrição"
                placeholder="Descrição..."
                onChange={({ target }) =>
                  onChangeValues('shared_links_settings', {
                    ...settings,
                    description: target.value,
                  })
                }
                defaultValue={settings?.description || ''}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Header>Integração API</Header>
            <Form>
              <FormInput
                fluid
                label="Endpoint"
                placeholder="Endpoint"
                onChange={({ target }) =>
                  onChangeValues('shared_links_api_integration', {
                    ...integration,
                    url: target.value,
                  })
                }
                defaultValue={integration?.url}
              />
              <div className="field">
                <label>Cliente:</label>
                <Form.Select
                  placeholder="Selecionar cliente"
                  onChange={(e, { value }) => {
                    onChangeValues('shared_links_api_integration', {
                      ...integration,
                      service: value,
                    })
                  }}
                  options={customerToIntegrate}
                  value={integration?.service}
                />
              </div>

              <FormInput
                fluid
                label="Parâmetro de rastreio"
                placeholder="Parâmetro de rastreio de leads da missão. Ex: aff_sub ou aud"
                onChange={({ target }) =>
                  onChangeValues('shared_links_api_integration', {
                    ...integration,
                    track_param: target.value,
                  })
                }
                defaultValue={integration?.track_param}
              />
              <FormTextArea
                fluid
                label="Headers"
                placeholder="Headers"
                type="text"
                onChange={({ target }) =>
                  onChangeValues('shared_links_api_integration', {
                    ...integration,
                    headers: target.value,
                  })
                }
                defaultValue={JSON.stringify(integration?.headers, null, 2)}
              />
              <FormSelect
                fluid
                label="Method"
                name="method"
                defaultValue={integration?.method}
                options={options}
                onChange={(e, { value }) => {
                  onChangeValues('shared_links_api_integration', {
                    ...integration,
                    method: value,
                  })
                }}
                placeholder="Method"
              />
              <FormInput
                fluid
                label="Result"
                placeholder="Result Key..."
                onChange={({ target }) =>
                  onChangeValues('shared_links_api_integration', {
                    ...integration,
                    result: target.value,
                  })
                }
                defaultValue={integration?.result}
              />
            </Form>
          </Grid.Column>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => setOpen(false)}>Fechar</Button>
      </ModalActions>
    </Modal>
  )
}

SharedLinkModal.propTypes = {
  open: t.bool,
  integration: t.objectOf(),
  settings: t.objectOf(),
  setOpen: t.func,
  onChange: t.func,
}
